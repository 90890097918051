import { Component } from "react";
import NavBar from "../components/NavBar";
import { Button, Card, CardBody, CardSubtitle, CardText, CardTitle, Col, DropdownItem, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import arbysLogo from "../assets/images/logo/Recurso_1.png";
import Device from "../config/Device.config";
/** Importación de imágenes */
import colors from "../config/Color.config";
import { IconButton } from "@material-ui/core";
import { Close, Map, QueryBuilder, Room, Streetview } from "@material-ui/icons";

/** Importacion de las imganes de las unidades de dominos*/
//import img99 from "../assets/images/unidades/";
import img1 from "../assets/images/unidades/VilladelRio.jpg";
import img2 from "../assets/images/unidades/Aeropuerto.jpg";
import img3 from "../assets/images/unidades/SanFrancisco.jpg";
import img4 from "../assets/images/unidades/Centro.jpg";
import img5 from "../assets/images/unidades/PuertoReal.jpg";
import img6 from "../assets/images/unidades/Fracciorama.jpg";
import img7 from "../assets/images/unidades/WalMart.jpg";
import img8 from "../assets/images/unidades/LasFloresTonala.jpg";
import img9 from "../assets/images/unidades/Boulevard.jpg";
import img10 from "../assets/images/unidades/PlazaCrystal.jpg";
import img11 from "../assets/images/unidades/GaleriasBoulevard.jpg";
import img12 from "../assets/images/unidades/PlazaSol.jpg";
import img13 from "../assets/images/unidades/Tecnologico.jpg";
import img14 from "../assets/images/unidades/PlazaTeran.jpg";
import img15 from "../assets/images/unidades/Unach.jpg";
import img16 from "../assets/images/unidades/LasFloresVillaflores.jpg";
import img17 from "../assets/images/unidades/XOXO.jpg";
import img18 from "../assets/images/unidades/Puertoescondido.jpg";
import img19 from "../assets/images/unidades/SorianaOaxaca.jpg";
import img20 from "../assets/images/unidades/MacroPlazaExpress.jpeg";
import img21 from "../assets/images/unidades/Universidad.jpg";
import img22 from "../assets/images/unidades/ZonaMilitar.jpg";
import img23 from "../assets/images/unidades/TuxtepecBlvd.jpeg";
import img24 from "../assets/images/unidades/PlazaSanJoaquin.jpg";
import img25 from "../assets/images/unidades/SanJoaquinDining.png";
import img26 from "../assets/images/unidades/SorianaGuayabal.jpg";
import img27 from "../assets/images/unidades/ZonaLuz.jpg";
import img28 from "../assets/images/unidades/Cunduacan.jpeg";
import img29 from "../assets/images/unidades/Cardenas.jpg";
import img30 from "../assets/images/unidades/Altabrisa.jpg";
import img31 from "../assets/images/unidades/Carrizal.jpg";
import img32 from "../assets/images/unidades/Crystal.jpg";
import img33 from "../assets/images/unidades/Galas.jpeg";
import img34 from "../assets/images/unidades/PlazaCrystalMinatitlan.jpg";
import img35 from "../assets/images/unidades/Minatitlan.jpg";
import img36 from "../assets/images/unidades/PlazalaFloridaExpress.jpg";
import Block from "../components/Block";

class ListDominos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            orientation: 0,
            device: {
                isDesktopDevice: false,
                isMobileDevice: true,
                screenHeight: 844,
                screenWidth: 390,
            },
            estaAbiertoModalUnidadDominos: false,
            unidadDominos: {
                IdUnidad: 0,
                UDN: "",
                marca: "",
                estado: "",
                ciudad: "",
                nombre: "",
                direccion: "",
                urlGoogleMaps: "",
                urlGoogleStreetView: "",
                IdStatus: 0,
                status: ""
            },
            listaUnidadesDominos: [
                {
                    IdUnidad: 37,
                    UDN: "11303",
                    marca: "Domino's GPM",
                    estado: "Campeche",
                    ciudad: "Campeche",
                    nombreUnidad: "Villa del Rio",
                    direccion: "Av. Francisco I. Madero 1, Villa del Río, Barrio de Sta Ana, 24010 Campeche, Camp.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3879292.136824206!2d-94.31705299681799!3d18.28462386870205!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85f83150dff40df5%3A0x39f536bdc25a0802!2sDomino's%20Campeche%20Villa%20Del%20R%C3%ADo!5e0!3m2!1ses!2smx!4v1687978912941!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Domino's+Campeche+Villa+Del+R%C3%ADo/@19.8453779,-90.5189984,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipPJlRqUr-0vXpjoi8Z9PSm5NSlfGRhtiyYJrH_v!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipPJlRqUr-0vXpjoi8Z9PSm5NSlfGRhtiyYJrH_v%3Dw152-h86-k-no!7i4128!8i2322!4m21!1m13!4m12!1m4!2m2!1d-93.1388574!2d16.7521291!4e1!1m6!1m2!1s0x85f83150dff40df5:0x39f536bdc25a0802!2sdomino's+pizza+Campeche+Villa+del+Rio!2m2!1d-90.5189984!2d19.8453779!3m6!1s0x85f83150dff40df5:0x39f536bdc25a0802!8m2!3d19.8453779!4d-90.5189984!10e5!16s%2Fg%2F1thn5w9g?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img1
                },
                {
                    IdUnidad: 32,
                    UDN: "11158",
                    marca: "Domino's GPM",
                    estado: "Campeche",
                    ciudad: "Ciudad del Carmen",
                    nombreUnidad: "Aeropuerto",
                    direccion: "Calle 31 8-BIS , Petrolera, Carmen, Ciudad del Carmen, México",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15121.368575810577!2d-91.8039118!3d18.6486365!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85f107892df91f07%3A0xc71e60166f991b7b!2sDomino's%20Cd.%20Del%20Carmen!5e0!3m2!1ses!2smx!4v1693503276036!5m2!1ses!2smx",
                    urlGoogleStreetView: "",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img2
                },
                {
                    IdUnidad: 33,
                    UDN: "11304",
                    marca: "Domino's GPM",
                    estado: "Campeche",
                    ciudad: "Ciudad del Carmen",
                    nombreUnidad: "San Francisco",
                    direccion: "Carretera Carmen-Puerto Real #Km 5.5 Mza B, Local 10, 24158 Cd del Carmen, Camp.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1946097.4050714648!2d-93.83708215056517!3d17.698806691404638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85f10654d8771675%3A0xfbb6f10e1773499b!2sDomino's%20Cd.%20Del%20Carmen%20San%20Francisco!5e0!3m2!1ses!2smx!4v1687978363287!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Domino's+Cd.+Del+Carmen+San+Francisco/@18.653585,-91.792123,3a,75y,160h,90t/data=!3m8!1e1!3m6!1sAF1QipMZw50e1H6o4tBynHzeCpksq9TroTj5NPVwlyqS!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMZw50e1H6o4tBynHzeCpksq9TroTj5NPVwlyqS%3Dw203-h100-k-no-pi0-ya340.6-ro-0-fo100!7i5568!8i2784!4m21!1m13!4m12!1m4!2m2!1d-93.1388564!2d16.7521294!4e1!1m6!1m2!1s0x85f10654d8771675:0xfbb6f10e1773499b!2sdomino's+pizza+Campeche%09Ciudad+del+Carmen%09San+Francisco!2m2!1d-91.792205!2d18.6535474!3m6!1s0x85f10654d8771675:0xfbb6f10e1773499b!8m2!3d18.6535474!4d-91.792205!10e5!16s%2Fg%2F1ptwb3d7j?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img3
                },
                {
                    IdUnidad: 34,
                    UDN: "11462",
                    marca: "Domino's GPM",
                    estado: "Campeche",
                    ciudad: "Ciudad del Carmen",
                    nombreUnidad: "Centro",
                    direccion: "C. 24 53, Centro, 24100 Cd del Carmen, Camp.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1946102.6297134873!2d-93.85520240342117!3d17.698324666645384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85f1a82118ca5605%3A0x166a1ba9994575d9!2sDomino's%20Cd.%20Del%20Carmen%20III!5e0!3m2!1ses!2smx!4v1687978463152!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Domino's+Cd.+Del+Carmen+III/@18.6368638,-91.8346448,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipO30ygIi-8A3jx8whxY0v9DBRYdHLL7uLgW0twn!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipO30ygIi-8A3jx8whxY0v9DBRYdHLL7uLgW0twn%3Dw225-h86-k-no!7i9376!8i3568!4m21!1m13!4m12!1m4!2m2!1d-93.1388554!2d16.752129!4e1!1m6!1m2!1s0x85f1a82118ca5605:0x166a1ba9994575d9!2sdomino's+pizza+Campeche+Ciudad+del+Carmen+Centro!2m2!1d-91.8346894!2d18.6368156!3m6!1s0x85f1a82118ca5605:0x166a1ba9994575d9!8m2!3d18.6368!4d-91.8347097!10e5!16s%2Fg%2F1ptytq25x?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img4
                },
                {
                    IdUnidad: 35,
                    UDN: "11523",
                    marca: "Domino's GPM",
                    estado: "Campeche",
                    ciudad: "Ciudad del Carmen",
                    nombreUnidad: "Puerto Real",
                    direccion: "Carmen - Puerto Real Km 5.5-Mza B, Residencial del Lago, 24157 Cd del Carmen, Camp.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7560.4689913591055!2d-91.77513342154317!3d18.653470349296942!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85f106f5bc9b2947%3A0xf46fac3d08916cdb!2sDomino's%20Puerto%20Real!5e0!3m2!1ses!2smx!4v1687978656377!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Domino's+Puerto+Real/@18.6585887,-91.7764608,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipOrWMyx2O-E0Fjf_q0Y3Vmk25i75vVkWYj5Q7fG!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipOrWMyx2O-E0Fjf_q0Y3Vmk25i75vVkWYj5Q7fG%3Dw203-h152-k-no!7i4048!8i3036!4m20!1m12!4m11!1m3!2m2!1d-91.776893!2d18.6638488!1m6!1m2!1s0x85f106f5bc9b2947:0xf46fac3d08916cdb!2sCarmen+-+Puerto+Real+Km+5.5-Mza+B,+Residencial+del+Lago,+24157+Cd+del+Carmen,+Camp.!2m2!1d-91.7765455!2d18.658816!3m6!1s0x85f106f5bc9b2947:0xf46fac3d08916cdb!8m2!3d18.65882!4d-91.776547!10e5!16s%2Fg%2F1ptz4cws3?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img5
                },
                {
                    IdUnidad: 36,
                    UDN: "11147",
                    marca: "Domino's GPM",
                    estado: "Campeche",
                    ciudad: "Campeche",
                    nombreUnidad: "Fracciorama",
                    direccion: "Av. Patricio Trueba de Regil 1 , Fracciorama 2000, Campeche, San Francisco de Campeche, Campeche, Mexico",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3879464.453784686!2d-94.32899765477941!3d18.276919814781113!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85f833e7f78f8c0f%3A0x3ea5e053b2d3f372!2sAv%20Patricio%20Trueba%20de%20Regil%201%2C%20Fracciorama%202000%2C%2024090%20Campeche%2C%20Camp.!5e0!3m2!1ses!2smx!4v1687978815299!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Reserva+de+la+Biosfera+Sierra+Gorda/@19.8299221,-90.5348164,3a,75y,78.45h,90t/data=!3m7!1e1!3m5!1sFyhZj0Awe5HOasR_TxncNA!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DFyhZj0Awe5HOasR_TxncNA%26cb_client%3Dsearch.gws-prod.gps%26w%3D86%26h%3D86%26yaw%3D78.4518%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192!4m18!1m10!4m9!1m4!2m2!1d-93.1388575!2d16.7521273!4e1!1m3!2m2!1d-90.534629!2d19.829946!3m6!1s0x85d46913dbb7365f:0x1f043662050705cd!8m2!3d21.3040018!4d-99.456155!10e5!16zL20vMGJ4c3Y0?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img6
                },
                {
                    IdUnidad: 10,
                    UDN: "11922",
                    marca: "Domino's GPM",
                    estado: "Chiapas",
                    ciudad: "Comitan de Dominguez",
                    nombreUnidad: "Wal Mart",
                    direccion: "Boulevard de Las Federaciones 4021, Chichima, Los Sabinos, 30039 Comitán de Domínguez, Chis.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3221.4909802869183!2d-92.115334174021!3d16.21941176434362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x858d3f186dc41e73%3A0xe6301951b4165842!2sDomino's%20Pizza%20%5BComit%C3%A1n%20Plaza%20las%20Flores%5D!5e0!3m2!1ses!2smx!4v1687971334973!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Domino's+Pizza+%5BComit%C3%A1n+Plaza+las+Flores%5D/@16.2173582,-92.1140777,3a,87.6y,90t/data=!3m8!1e2!3m6!1sAF1QipNBuUiH9R19g5RDVoEa5j7tPqJrigrAiZ6_HwQR!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipNBuUiH9R19g5RDVoEa5j7tPqJrigrAiZ6_HwQR%3Dw152-h86-k-no!7i1280!8i720!4m20!1m12!4m11!1m3!2m2!1d-92.114767!2d16.2227861!1m6!1m2!1s0x858d3f186dc41e73:0xe6301951b4165842!2sBoulevard+de+Las+Federaciones+4021,+Chichima,+Los+Sabinos,+30039+Comit%C3%A1n+de+Dom%C3%ADnguez,+Chis.!2m2!1d-92.1140777!2d16.2173582!3m6!1s0x858d3f186dc41e73:0xe6301951b4165842!8m2!3d16.2173582!4d-92.1140777!10e5!16s%2Fg%2F11bxf9rf55?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img7
                },
                {
                    IdUnidad: 11,
                    UDN: "11961",
                    marca: "Domino's GPM",
                    estado: "Chiapas",
                    ciudad: "Tonala",
                    nombreUnidad: "Las Flores Tonalá",
                    direccion: "Av. Hidalgo 312, Int. 41, San Ramón, Tonalá, Chiapas, Mexico",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d4558.650646763609!2d-93.77036901306725!3d16.099030769660644!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85949fa59a115a1b%3A0x576f8bcb0f363c64!2sAerocel%20Plaza%20Las%20Flores%20Tonala%20Isla%205.%20Distribuidor%20Autorizado%20Telcel!5e0!3m2!1ses!2smx!4v1687971703329!5m2!1ses!2smx",
                    urlGoogleStreetView: "",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img8
                },
                {
                    IdUnidad: 12,
                    UDN: "11051",
                    marca: "Domino's GPM",
                    estado: "Chiapas",
                    ciudad: "Tuxtla Gutierrez",
                    nombreUnidad: "Boulevard",
                    direccion: "Doctor Belisario Domínguez Km 1084, Arboledas, 29030 Tuxtla Gutiérrez, Chis.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7423.368440289302!2d-93.13774593124784!3d16.75452960666929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ecd8fc41a5c277%3A0x65a5184892c7976b!2sDomino's%20Pizza%20Boulevard!5e0!3m2!1ses!2smx!4v1687971816440!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85ecd8fc41a5c277:0x65a5184892c7976b/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipNRC59tQox5HiD4OEQGii7ENkLw6WEF03y4uQ7f%3Dw160-h106-k-no-pi0-ya248.8959-ro-0-fo100&ik=CAoSLEFGMVFpcE5SQzU5dFFveDVIaUQ0T0VRR2lpN0VOa0x3NldFRjAzeTR1UTdm",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img9
                },
                {
                    IdUnidad: 13,
                    UDN: "11711",
                    marca: "Domino's GPM",
                    estado: "Chiapas",
                    ciudad: "Tuxtla Gutierrez",
                    nombreUnidad: "Plaza Crystal",
                    direccion: "Plaza Crystal Loc. 7-C, Blvd. Belisario Domínguez Km. 1084, Bugambilias, 29000 Tuxtla Gutiérrez, Chis.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3820.460739118297!2d-93.1526287241286!3d16.753737720748518!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ecd93676920bbd%3A0xd6f916f158f7d3f0!2sDomino's%20Plaza%20Crystal%20Tuxtla!5e0!3m2!1ses!2smx!4v1687974222367!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com.mx/local/place/fid/0x85ecd93676920bbd:0xd6f916f158f7d3f0/photosphere?iu=https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid%3D44xbJkr0UZNjCgWgaTHiyA%26cb_client%3Dlu.gallery.gps%26w%3D160%26h%3D106%26yaw%3D100.84865%26pitch%3D0%26thumbfov%3D100&ik=CAISFjQ0eGJKa3IwVVpOakNnV2dhVEhpeUE%3D",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img10
                },
                {
                    IdUnidad: 14,
                    UDN: "11880",
                    marca: "Domino's GPM",
                    estado: "Chiapas",
                    ciudad: "Tuxtla Gutierrez",
                    nombreUnidad: "Galerías Boulevard",
                    direccion: "Boulevard Belisario Domínguez Km. 1084. Plaza Galerias, Bugambilias, 29020 Tuxtla Gutiérrez, Chis.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7641.010333695792!2d-93.15081943071168!3d16.751524274281667!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ecd90f3e840337%3A0x1c4224b9a2a258b1!2sDomino's%20Pizza%20Galer%C3%ADas%20Boulevard!5e0!3m2!1ses!2smx!4v1687974355768!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com.mx/maps/place/Domino's+Pizza+Galer%C3%ADas+Boulevard/@16.7541186,-93.1485122,3a,75y,90t/data=!3m8!1e2!3m6!1sqyjQek3JlH4AAAQvObSufQ!2e0!3e3!6shttps:%2F%2Flh3.googleusercontent.com%2Fgps-proxy%2FAE4_-5H8XhA64XHAWf_TKoWs05pPwUyXg72Dpxa539I8Epf8zlzx5WBXIKmoWeCN7Cg0uD4TgU-MFQfEGagy1WbCvvr3BpHCVoBqygv9W5tPb1d8gTzWKC-lE4VOWS-P4cVCfBvKCtTTe_Hsxzzna0mHlweIduKIt-ij0_1Sas_4nP2HjGfawvtjsY2S7p7ZheLbDys0Vno%3Dw203-h361-k-no!7i1456!8i2592!4m21!1m13!4m12!1m4!2m2!1d-93.1388561!2d16.7521339!4e1!1m6!1m2!1s0x85ecd90f3e840337:0x1c4224b9a2a258b1!2sdominos+pizza+Galer%C3%ADas+Boulevard!2m2!1d-93.1485942!2d16.7541365!3m6!1s0x85ecd90f3e840337:0x1c4224b9a2a258b1!8m2!3d16.7541365!4d-93.1485942!10e5!16s%2Fg%2F11ckkxxtzd?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img11
                },
                {
                    IdUnidad: 15,
                    UDN: "11894",
                    marca: "Domino's GPM",
                    estado: "Chiapas",
                    ciudad: "Tuxtla Gutierrez",
                    nombreUnidad: "Plaza sol",
                    direccion: "Quinta Avenida Norte Poniente # 2651, CENTRO, 29000 Tuxtla Gutiérrez, Chis.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15281.248371745896!2d-93.14895873022462!3d16.7611414!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ecd8bc10076201%3A0x8ea4bb1ecaec08a8!2sDominos%20%5BPlaza%20del%20Sol%5D!5e0!3m2!1ses!2smx!4v1687974503282!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com.mx/maps/place/Dominos+%5BPlaza+del+Sol%5D/@16.7611047,-93.1402131,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipPlWkoY8H_9rXGiyWrLIJ3aU54OY-x_KFyUchyJ!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipPlWkoY8H_9rXGiyWrLIJ3aU54OY-x_KFyUchyJ%3Dw203-h270-k-no!7i3024!8i4032!4m21!1m13!4m12!1m4!2m2!1d-93.1388561!2d16.7521339!4e1!1m6!1m2!1s0x85ecd8bc10076201:0x8ea4bb1ecaec08a8!2sdominos+pizza+plaza+sol+tuxtla!2m2!1d-93.140204!2d16.7611414!3m6!1s0x85ecd8bc10076201:0x8ea4bb1ecaec08a8!8m2!3d16.7611414!4d-93.140204!10e5!16s%2Fg%2F11f40lfzys?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img12
                },
                {
                    IdUnidad: 16,
                    UDN: "11493",
                    marca: "Domino's GPM",
                    estado: "Chiapas",
                    ciudad: "Tuxtla Gutierrez",
                    nombreUnidad: "Tecnológico",
                    direccion: "Carr. Panamericana # 3867, Terán, 29050 Tuxtla Gutiérrez, Chis.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30562.932545244214!2d-93.17996678747303!3d16.758428500000008!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ecd9652e1bf821%3A0xe550282927cb9270!2sDomino's%20Pizza%20Tecnol%C3%B3gico!5e0!3m2!1ses!2smx!4v1687974680341!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com.mx/maps/place/Domino's+Pizza+Tecnol%C3%B3gico/@16.7586178,-93.169753,3a,75y,211.29h,75.79t/data=!3m8!1e1!3m6!1sAF1QipP-hUdqH-5i5j8gOfy_KEmuLmHXerTSTAl4W85f!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipP-hUdqH-5i5j8gOfy_KEmuLmHXerTSTAl4W85f%3Dw203-h100-k-no-pi-10-ya234.69159-ro0-fo100!7i13312!8i6656!4m21!1m13!4m12!1m4!2m2!1d-93.1388565!2d16.7521314!4e1!1m6!1m2!1s0x85ecd9652e1bf821:0xe550282927cb9270!2sdominos+pizza+tecnologico!2m2!1d-93.1695406!2d16.7584863!3m6!1s0x85ecd9652e1bf821:0xe550282927cb9270!8m2!3d16.7584285!4d-93.1695556!10e5!16s%2Fg%2F1tftx9lw?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img13
                },
                {
                    IdUnidad: 17,
                    UDN: "12409",
                    marca: "Domino's GPM",
                    estado: "Chiapas",
                    ciudad: "Tuxtla Gutierrez",
                    nombreUnidad: "Plaza Teran",
                    direccion: "2a ote nte #327 local 10 y 20 plaza, Terán, 29050 Tuxtla Gutiérrez, Chis.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9086.720985790886!2d-93.1640972130762!3d16.75200333830899!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ecd98937750679%3A0xe6d83b669602df62!2sDomino's%20pizza%20plaza%20Teran!5e0!3m2!1ses!2smx!4v1687974750009!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com.mx/local/place/fid/0x85ecd98937750679:0xe6d83b669602df62/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipMOQ6eUDJZZM1d0y5xCOA-UkT3kdbTtN1FsckXa%3Dw160-h106-k-no-pi-0-ya225.17703-ro0-fo100&ik=CAoSLEFGMVFpcE1PUTZlVURKWlpNMWQweTV4Q09BLVVrVDNrZGJUdE4xRnNja1hh",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img14
                },
                {
                    IdUnidad: 18,
                    UDN: "11581",
                    marca: "Domino's GPM",
                    estado: "Chiapas",
                    ciudad: "Tuxtla Gutierrez",
                    nombreUnidad: "Unach",
                    direccion: "Blvd. Belisario Domínguez 2829, Jardines de Tuxtla, 29020 Tuxtla Gutiérrez, Chis.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d51402.73934582915!2d-93.18291499900187!3d16.750214179747072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ecd90e8b3cb617%3A0xe6de455d762bc5ef!2sDomino's%20Pizza%20UNACH!5e0!3m2!1ses!2smx!4v1687974891686!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85ecd90e8b3cb617:0xe6de455d762bc5ef/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipPYzOw01yyUSkQ_OwAnL_khW4kRXPObgLBbcYVz%3Dw160-h106-k-no-pi-20-ya317.63248-ro-0-fo100&ik=CAoSLEFGMVFpcFBZek93MDF5eVVTa1FfT3dBbkxfa2hXNGtSWFBPYmdMQmJjWVZ6",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img15
                },
                {
                    IdUnidad: 19,
                    UDN: "11974",
                    marca: "Domino's GPM",
                    estado: "Chiapas",
                    ciudad: "Villaflores",
                    nombreUnidad: "Las Flores Villaflores",
                    direccion: "Calle 14a. Pte. No. 48, La Concepcion, 30470 Villaflores, Chis.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d489658.4335325886!2d-93.53973638451133!3d16.503035096383396!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85933ca0ce26da61%3A0x22fec71feecdc9a5!2sDominos%20Pizza!5e0!3m2!1ses!2smx!4v1687976497175!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Dominos+Pizza/@16.2243948,-93.2738186,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipP_kNaaChncimTEGunO1YIs1-ggY5dx3E7DGfB9!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipP_kNaaChncimTEGunO1YIs1-ggY5dx3E7DGfB9%3Dw203-h114-k-no!7i1920!8i1080!4m21!1m13!4m12!1m4!2m2!1d-93.1388434!2d16.7521411!4e1!1m6!1m2!1s0x85933ca0ce26da61:0x22fec71feecdc9a5!2sdominos+pizza+Chiapas+Villaflores+Las+Flores+Villaflores!2m2!1d-93.2738186!2d16.2243948!3m6!1s0x85933ca0ce26da61:0x22fec71feecdc9a5!8m2!3d16.2243948!4d-93.2738186!10e5!16s%2Fg%2F11c60fh1s3?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img16
                },
                {
                    IdUnidad: 31,
                    UDN: "12530",
                    marca: "Domino's GPM",
                    estado: "Oaxaca",
                    ciudad: "Oaxaca de Juarez",
                    nombreUnidad: "XOXO",
                    direccion: "Guadalupe Hinojosa de Murat 405-lote B, Sta Cruz Xoxocotlan, 71230 Santa Cruz Xoxocotlán, Oax.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1956117.5414018624!2d-97.22066690670121!3d16.74968534389483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c7237c2e43c15b%3A0xd9ee62c5e6e3159a!2sDomino's%20XOXO!5e0!3m2!1ses!2smx!4v1687978076369!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Domino's+XOXO/@17.0224508,-96.7303324,3a,75y,105.68h,90t/data=!3m7!1e1!3m5!1sO27IdqaXLBm5PEIAimyrJw!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DO27IdqaXLBm5PEIAimyrJw%26cb_client%3Dmaps_sv.tactile.gps%26w%3D203%26h%3D100%26yaw%3D105.67764%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192!4m21!1m13!4m12!1m4!2m2!1d-93.1388581!2d16.7521289!4e1!1m6!1m2!1s0x85c7237c2e43c15b:0xd9ee62c5e6e3159a!2sdomino's+pizza+Oaxaca%09Oaxaca+de+Juarez%09XOXO!2m2!1d-96.7301889!2d17.02241!3m6!1s0x85c7237c2e43c15b:0xd9ee62c5e6e3159a!8m2!3d17.02241!4d-96.7301889!10e5!16s%2Fg%2F11sgs19f68?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img17
                },
                {
                    IdUnidad: 30,
                    UDN: "12568",
                    marca: "Domino's GPM",
                    estado: "Oaxaca",
                    ciudad: "Puerto escondido",
                    nombreUnidad: "Puerto escondido",
                    direccion: "Av. Alfonso Pérez Gasga Supermanzana Gasca 114, El, Marinero, 71986 Puerto Escondido, Oax.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3931351.032945048!2d-97.35132154486635!3d15.792877560740033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85b8f7d2755bc33b%3A0xb3d01a8d678eb762!2sDominos%20Puerto%20Escondido!5e0!3m2!1ses!2smx!4v1687977749784!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Dominos+Puerto+Escondido/@15.8612582,-97.0613632,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipM5BFGIIizMjNcl5ZSivOQQmQ2fcEasy8_ck7gy!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipM5BFGIIizMjNcl5ZSivOQQmQ2fcEasy8_ck7gy%3Dw114-h86-k-no!7i4032!8i3024!4m21!1m13!4m12!1m4!2m2!1d-93.1388579!2d16.7521259!4e1!1m6!1m2!1s0x85b8f7d2755bc33b:0xb3d01a8d678eb762!2sdomino's+pizza+Oaxaca%09Puerto+escondido%09Puerto+escondido!2m2!1d-97.0613643!2d15.8612532!3m6!1s0x85b8f7d2755bc33b:0xb3d01a8d678eb762!8m2!3d15.8612532!4d-97.0613643!10e5!16s%2Fg%2F11s4cvqj1x?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img18
                },
                {
                    IdUnidad: 4,
                    UDN: "11890",
                    marca: "Domino's GPM",
                    estado: "Oaxaca",
                    ciudad: "Oaxaca de Juarez",
                    nombreUnidad: "Soriana Oaxaca",
                    direccion: "Av. Jorge L. Tamayo Castillejos 500, Col, Exhacienda Candiani, 68130 Oaxaca de Juárez, Oax.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7629.220312178688!2d-96.72121184130859!3d17.042781900000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c722573fffffef%3A0x741fb3900eee2d60!2sDomino's!5e0!3m2!1ses!2smx!4v1687969949577!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com.mx/maps/place/Domino's/@17.0428421,-96.7160108,3a,75y,282.65h,66.12t/data=!3m8!1e1!3m6!1sAF1QipPOqkYjsIN2evzCNKYtPZnSXVU23A0gOSr8YEKx!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipPOqkYjsIN2evzCNKYtPZnSXVU23A0gOSr8YEKx%3Dw203-h100-k-no-pi-0-ya189.83572-ro0-fo100!7i13312!8i6656!4m11!1m2!2m1!1sdominos+pizza!3m7!1s0x85c722573fffffef:0x741fb3900eee2d60!8m2!3d17.0427819!4d-96.716062!10e5!15sCg1kb21pbm9zIHBpenphIgOIAQFaDyINZG9taW5vcyBwaXp6YZIBEHBpenphX3Jlc3RhdXJhbnTgAQA!16s%2Fg%2F11cnt2cd1t?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img19
                },
                {
                    IdUnidad: 5,
                    UDN: "12412",
                    marca: "Domino's GPM",
                    estado: "Oaxaca",
                    ciudad: "Oaxaca de Juarez",
                    nombreUnidad: "Macro Plaza Express",
                    direccion: "Avenida Internacional 2002 Nueva Santa Lucia Del Camino, 71244 Oaxaca de Juárez",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913474.347569735!2d-97.15760629142912!3d16.689274525366347!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c723887a68c2e7%3A0x4776994eeb9d2ed0!2sDomino's%20Macroplaza%20Oaxaca!5e0!3m2!1ses!2smx!4v1687970027157!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85c723887a68c2e7:0x4776994eeb9d2ed0/photosphere?iu=https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid%3DY-WyJMMbHFRhrKgiUlEEzw%26cb_client%3Dlu.gallery.gps%26w%3D160%26h%3D106%26yaw%3D198.51419%26pitch%3D0%26thumbfov%3D100&ik=CAISFlktV3lKTU1iSEZSaHJLZ2lVbEVFenc%3D",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img20
                },
                {
                    IdUnidad: 7,
                    UDN: "11311",
                    marca: "Domino's GPM",
                    estado: "Oaxaca",
                    ciudad: "Oaxaca de Juarez",
                    nombreUnidad: "Universidad",
                    direccion: "Avenida Universidad 300, Universidad, Trinidad de las Huertas, 68120 Oaxaca de Juárez, Oax.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d122062.80444729648!2d-96.783833188471!3d17.050008399497255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c722574796406d%3A0x271679d37b9572ff!2sDomino's%20Oaxaca%20Universidad!5e0!3m2!1ses!2smx!4v1687970184326!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85c722574796406d:0x271679d37b9572ff/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipNzV4-i-VqE_gesrPgLWBmrkE1irkDwb8rWP1Dt%3Dw160-h106-k-no-pi-0-ya222.31422-ro-0-fo100&ik=CAoSLEFGMVFpcE56VjQtaS1WcUVfZ2VzclBnTFdCbXJrRTFpcmtEd2I4cldQMUR0",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img21
                },
                {
                    IdUnidad: 8,
                    UDN: "11559",
                    marca: "Domino's GPM",
                    estado: "Oaxaca",
                    ciudad: "Oaxaca de Juarez",
                    nombreUnidad: "Zona Militar",
                    direccion: "Carr. Internacional 411, Del, El Bosque Nte., 71244 Santa Lucía del Camino, Oax.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3913471.8795013656!2d-97.15958513771653!3d16.689395048159763!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c723cf3ea1e3f5%3A0x9c2c72bd3450cbd!2sDomino%C2%B4s%20Zona%20Militar%20Oaxaca!5e0!3m2!1ses!2smx!4v1687970740497!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85c723cf3ea1e3f5:0x9c2c72bd3450cbd/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipNg-XGcMnoYCl4gCmamntcEEqvsqUzC6gbBFU7I%3Dw160-h106-k-no-pi-20-ya301.59906-ro-0-fo100&ik=CAoSLEFGMVFpcE5nLVhHY01ub1lDbDRnQ21hbW50Y0VFcXZzcVV6QzZnYkJGVTdJ",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img22
                },
                {
                    IdUnidad: 9,
                    UDN: "11534",
                    marca: "Domino's GPM",
                    estado: "Oaxaca",
                    ciudad: "Oaxaca de Juarez",
                    nombreUnidad: "Tuxtepec Blvd",
                    direccion: "Blvd. Belisario Juarez 1220 , Maria Luisa, San Juan Bautista Tuxtepec, San Juan Bautista Tuxtepec, Oaxaca, Mexico",
                    urlGoogleMaps: " https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3895821.7090328787!2d-96.87821142053372!3d17.530727512423162!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85c3e6f4de31229d%3A0x2c1e6ec30c5cf0ce!2sBenito%20Ju%C3%A1rez%201220%2C%20Mar%C3%ADa%20Luisa%2C%2068310%20San%20Juan%20Bautista%20Tuxtepec%2C%20Oax.!5e0!3m2!1ses!2smx!4v1687970877909!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Benito+Ju%C3%A1rez+1220,+Mar%C3%ADa+Luisa,+68310+San+Juan+Bautista+Tuxtepec,+Oax./@18.090044,-96.1303811,3a,75y,130.94h,90t/data=!3m7!1e1!3m5!1sL6pWEhibk3Vv-oJN3uAdPA!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DL6pWEhibk3Vv-oJN3uAdPA%26cb_client%3Dmaps_sv.tactile.gps%26w%3D203%26h%3D100%26yaw%3D130.94202%26pitch%3D0%26thumbfov%3D100!7i16384!8i8192!4m18!1m10!4m9!1m4!2m2!1d-93.1388572!2d16.7521354!4e1!1m3!2m2!1d-96.130246!2d18.089849!3m6!1s0x85c3e6f4de31229d:0x2c1e6ec30c5cf0ce!8m2!3d18.0898824!4d-96.1301469!10e5!16s%2Fg%2F11c4ds1b3z?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img23
                },
                {
                    IdUnidad: 25,
                    UDN: "11472",
                    marca: "Domino's GPM",
                    estado: "Tabasco",
                    ciudad: "Villahermosa",
                    nombreUnidad: "Plaza San Joaquín",
                    direccion: "Perif. Carlos Pellicer Cámara N, 18 de Marzo, 86140 Villahermosa, Tab.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d974794.5244331524!2d-93.86575428715611!3d17.37378174073017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85edd704592125d3%3A0xd8cb68448877f026!2sDomino's%20San%20Joaqu%C3%ADn%20Villahermosa!5e0!3m2!1ses!2smx!4v1687977067017!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85edd704592125d3:0xd8cb68448877f026/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipOSLudlCK8x1kabylqBnylp9PXJMu1JZZ-eFLe3%3Dw160-h106-k-no-pi-0-ya15.269453-ro-0-fo100&ik=CAoSLEFGMVFpcE9TTHVkbENLOHgxa2FieWxxQm55bHA5UFhKTXUxSlpaLWVGTGUz",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img24
                },
                {
                    IdUnidad: 26,
                    UDN: "11995",
                    marca: "Domino's GPM",
                    estado: "Tabasco",
                    ciudad: "Villahermosa",
                    nombreUnidad: "Plaza San Joaquín Dinning",
                    direccion: "Perif. Carlos Pellicer Cámara N, 18 de Marzo, 86140 Villahermosa, Tab.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d974794.5244331524!2d-93.86575428715611!3d17.37378174073017!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85edd704592125d3%3A0xd8cb68448877f026!2sDomino's%20San%20Joaqu%C3%ADn%20Villahermosa!5e0!3m2!1ses!2smx!4v1687977067017!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85edd704592125d3:0xd8cb68448877f026/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipOSLudlCK8x1kabylqBnylp9PXJMu1JZZ-eFLe3%3Dw160-h106-k-no-pi-0-ya15.269453-ro-0-fo100&ik=CAoSLEFGMVFpcE9TTHVkbENLOHgxa2FieWxxQm55bHA5UFhKTXUxSlpaLWVGTGUz",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img25
                },
                {
                    IdUnidad: 27,
                    UDN: "11925",
                    marca: "Domino's GPM",
                    estado: "Tabasco",
                    ciudad: "Villahermosa",
                    nombreUnidad: "Soriana Guayabal",
                    direccion: "Av, Perif. Carlos Pellicer Cámara 205, Guayabal, 86090 Villahermosa, Tab.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d974785.950702393!2d-93.8360239352445!3d17.375392327417615!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85edd70459f3298d%3A0xe8438ee443aa6e8c!2sDomino's%20Pizza%20Soriana%20Guayabal!5e0!3m2!1ses!2smx!4v1687977405878!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85edd70459f3298d:0xe8438ee443aa6e8c/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipOMIzxeHRL-X_1eUCRNyq5uo5GXfkLRXQUpAtSL%3Dw160-h106-k-no-pi-20-ya352.69604-ro-0-fo100&ik=CAoSLEFGMVFpcE9NSXp4ZUhSTC1YXzFlVUNSTnlxNXVvNUdYZmtMUlhRVXBBdFNM",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img26
                },
                {
                    IdUnidad: 28,
                    UDN: "11410",
                    marca: "Domino's GPM",
                    estado: "Tabasco",
                    ciudad: "Villahermosa",
                    nombreUnidad: "Zona Luz",
                    direccion: "Ignacio Aldama 604, Centro Delegacion Dos, 86000 Villahermosa, Tab.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3794.7556032931507!2d-92.92062568255615!3d17.990104000000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85edd837f113f0ed%3A0xe8c53a1891656938!2sIgnacio%20Aldama%20604%2C%20Centro%20Delegacion%20Dos%2C%2086000%20Villahermosa%2C%20Tab.!5e0!3m2!1ses!2smx!4v1687977519442!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Ignacio+Aldama+604,+Centro+Delegacion+Dos,+86000+Villahermosa,+Tab./@17.9901998,-92.9185755,3a,75y,130.11h,90t/data=!3m7!1e1!3m5!1srdbuZoVzGf5wktkxF97Xfg!2e0!6shttps:%2F%2Fstreetviewpixels-pa.googleapis.com%2Fv1%2Fthumbnail%3Fpanoid%3DrdbuZoVzGf5wktkxF97Xfg%26cb_client%3Dsearch.gws-prod.gps%26w%3D86%26h%3D86%26yaw%3D130.10635%26pitch%3D0%26thumbfov%3D100!7i13312!8i6656!4m7!3m6!1s0x85edd837f113f0ed:0xe8c53a1891656938!8m2!3d17.990104!4d-92.918437!10e5!16s%2Fg%2F11h28b_9d_?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img27
                },
                {
                    IdUnidad: 29,
                    UDN: "12552",
                    marca: "Domino's GPM",
                    estado: "Tabasco",
                    ciudad: "Cunduacán",
                    nombreUnidad: "Cunduacán",
                    direccion: "Av Periférico 52, Centro, 86690 Cunduacán, Tab.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d974609.1823031952!2d-93.94736154583337!3d17.408566323889755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ee813f73130d5b%3A0xac6fb6e534e5fc77!2sDomino's%20Pizza%20Cunduac%C3%A1n!5e0!3m2!1ses!2smx!4v1687977620458!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85ee813f73130d5b:0xac6fb6e534e5fc77/photosphere?iu=https://streetviewpixels-pa.googleapis.com/v1/thumbnail?panoid%3D0h2LDOC_GXhA1ZXxPhiTEg%26cb_client%3Dlu.gallery.gps%26w%3D160%26h%3D106%26yaw%3D175.52943%26pitch%3D0%26thumbfov%3D100&ik=CAISFjBoMkxET0NfR1hoQTFaWHhQaGlURWc%3D",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img28
                },
                {
                    IdUnidad: 20,
                    UDN: "11533",
                    marca: "Domino's GPM",
                    estado: "Tabasco",
                    ciudad: "Cardenas",
                    nombreUnidad: "Cárdenas",
                    direccion: "Lázaro Cárdenas del Río, Centro, 86500 Heroica Cárdenas, Tab.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d974839.8039343493!2d-93.9473615972516!3d17.36527352523115!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ec20f815978c37%3A0x5b85ce34be70e607!2sDomino's%20Pizza!5e0!3m2!1ses!2smx!4v1687976432179!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85ec20f815978c37:0x5b85ce34be70e607/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipOid9noATylwGRiPzQ86kf_jH6FabT-bpmkI85I%3Dw160-h106-k-no-pi-10-ya227.76775-ro-0-fo100&ik=CAoSLEFGMVFpcE9pZDlub0FUeWx3R1JpUHpRODZrZl9qSDZGYWJULWJwbWtJODVJ",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img29
                },
                {
                    IdUnidad: 21,
                    UDN: "11934",
                    marca: "Domino's GPM",
                    estado: "Tabasco",
                    ciudad: "Villahermosa",
                    nombreUnidad: "Altabrisa",
                    direccion: "Plaza Altabrisa, Periferico Carlos Pellicer Cámara 501, Plutarco Elías Calles, 86190 Villahermosa, Tab.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d974794.2710622813!2d-93.8524329870996!3d17.373829338859036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85edd763482a2a7d%3A0x5cd965d1fbd5d246!2sDomino's!5e0!3m2!1ses!2smx!4v1687976622513!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Domino's/@17.9670763,-92.9407646,3a,75y,90t/data=!3m8!1e2!3m6!1sPvWoh7jtlbwAAAQfDRzsVQ!2e0!3e3!6shttps:%2F%2Flh3.googleusercontent.com%2Fgps-proxy%2FAE4_-5HZqyy9i0jNzqwtHEsifU9U3ZHwTDMMLDKjPfdL732zGPhjisJlJpMMokFlImxhCZQBGxdJAnQlLFHhrnLsyjtmcKw5jnzwp8z4RZ5pr8d0_26cBVcQw-PUSk3AU-urhGemgDQZTvre2S3Eu2ExBBtTbK3rnHLvxFGMeDVXlWWSgXbiQtJQOhVqGFjDihz61hyiRX0%3Dw203-h152-k-no!7i2592!8i1456!4m21!1m13!4m12!1m4!2m2!1d-93.138856!2d16.752136!4e1!1m6!1m2!1s0x85edd763482a2a7d:0x5cd965d1fbd5d246!2sdomino's+pizza+Tabasco+Villahermosa+Altabrisa!2m2!1d-92.9413309!2d17.9671239!3m6!1s0x85edd763482a2a7d:0x5cd965d1fbd5d246!8m2!3d17.9671239!4d-92.9413309!10e5!16s%2Fg%2F11b7q6qf3n?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img30
                },
                {
                    IdUnidad: 22,
                    UDN: "11920",
                    marca: "Domino's GPM",
                    estado: "Tabasco",
                    ciudad: "Villahermosa",
                    nombreUnidad: "Carrizal",
                    direccion: "Blvd. Adolfo Ruíz Cortínes S/N, Local 32-33, Col. Carrizal, 86038 Villahermosa, Tab.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d974794.2710622813!2d-93.86364323709961!3d17.373829338859036!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85edd79a5e3a1a61%3A0x9aeef6d7d31c95c0!2sDominos%20Pizza%20Chedraui%20Carrizal!5e0!3m2!1ses!2smx!4v1687976745463!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Dominos+Pizza+Chedraui+Carrizal/@17.988264,-92.963138,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipM5d5_A-d9AWE7xuGZvJpcX4ePDl7RplIqFOJtd!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipM5d5_A-d9AWE7xuGZvJpcX4ePDl7RplIqFOJtd%3Dw203-h152-k-no!7i4624!8i3468!4m21!1m13!4m12!1m4!2m2!1d-93.1388584!2d16.7521271!4e1!1m6!1m2!1s0x85edd79a5e3a1a61:0x9aeef6d7d31c95c0!2sdomino's+pizza+Tabasco%09Villahermosa%09Carrizal!2m2!1d-92.963138!2d17.988264!3m6!1s0x85edd79a5e3a1a61:0x9aeef6d7d31c95c0!8m2!3d17.988264!4d-92.963138!10e5!16s%2Fg%2F1ts2_mvl?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img31
                },
                {
                    IdUnidad: 23,
                    UDN: "11814",
                    marca: "Domino's GPM",
                    estado: "Tabasco",
                    ciudad: "Villahermosa",
                    nombreUnidad: "Crystal",
                    direccion: "Av Colegio Militar S/N, Primero de Mayo, 86190 Villahermosa, Tab.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d974794.5244331524!2d-93.8501709371561!3d17.373781740730163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85edd78191c6dd83%3A0x9480f8c1342d2d16!2sDomino's%20Pizza%20Plaza%20Crystal%20Villahermosa!5e0!3m2!1ses!2smx!4v1687976859519!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Domino's+Pizza+Plaza+Crystal+Villahermosa/@17.980842,-92.939809,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipObmhWxfsnWDxxupQr2EhXn5wRHuzT46Fxi43QX!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipObmhWxfsnWDxxupQr2EhXn5wRHuzT46Fxi43QX%3Dw114-h86-k-no!7i4032!8i3024!4m21!1m13!4m12!1m4!2m2!1d-93.1388576!2d16.7521295!4e1!1m6!1m2!1s0x85edd78191c6dd83:0x9480f8c1342d2d16!2sdomino's+pizza+Tabasco%09Villahermosa%09Crystal!2m2!1d-92.939809!2d17.980842!3m6!1s0x85edd78191c6dd83:0x9480f8c1342d2d16!8m2!3d17.980842!4d-92.939809!10e5!16s%2Fg%2F1tfs1f7w?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img32
                },
                {
                    IdUnidad: 24,
                    UDN: "11878",
                    marca: "Domino's GPM",
                    estado: "Tabasco",
                    ciudad: "Villahermosa",
                    nombreUnidad: "Galas",
                    direccion: "Av Francisco Javier Mina Local 12-A y B, Centro Delegacion Seis, 86000 Villahermosa, Tab.",
                    urlGoogleMaps: "https://www.google.com/local/place/fid/0x85edd83eb9f4970b:0xf34c38247ba0d14e/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipMHlUJZnHlDOIVhrTummLosUjJEnt5BY9ygHblv%3Dw160-h106-k-no-pi0-ya26.82603-ro-0-fo100&ik=CAoSLEFGMVFpcE1IbFVKWm5IbERPSVZoclR1bW1Mb3NVakpFbnQ1Qlk5eWdIYmx2",
                    urlGoogleStreetView: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d974785.950702393!2d-93.8429846352445!3d17.375392327417618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85edd83eb9f4970b%3A0xf34c38247ba0d14e!2sDomino's%20Pizza%20Plaza%20Las%20Galas!5e0!3m2!1ses!2smx!4v1687976945280!5m2!1ses!2smx",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img33
                },
                {
                    IdUnidad: 2,
                    UDN: "11933",
                    marca: "Domino's GPM",
                    estado: "Veracruz",
                    ciudad: "Minatitlan",
                    nombreUnidad: "Plaza Crystal Minatitlán",
                    direccion: "Boulevard Instituto Tecnológicos No. 49, Centro Comercial Plaza Crystal Minatitlán, Rosalinda, 96710 Minatitlán, Ver.",
                    urlGoogleMaps: " https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1949202.4761385894!2d-94.97425889843767!3d17.41005577409618!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ea27f629e36d47%3A0xbda218dd5dd36463!2sDomino's%20Pizza%20Plaza%20Crystal%20Minatitl%C3%A1n!5e0!3m2!1ses!2smx!4v1687969346133!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85ea27f629e36d47:0xbda218dd5dd36463/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipMrUZG7OVhirXCJnudRaZGlzaB03ZiiCueMowFb%3Dw160-h106-k-no-pi-20-ya114.731705-ro-0-fo100&ik=CAoSLEFGMVFpcE1yVVpHN09WaGlyWENKbnVkUmFaR2x6YUIwM1ppaUN1ZU1vd0Zi",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img34
                },
                {
                    IdUnidad: 3,
                    UDN: "11448",
                    marca: "Domino's GPM",
                    estado: "Veracruz",
                    ciudad: "Minatitlan",
                    nombreUnidad: "Minatitlán",
                    direccion: "Av Justo Sierra 145, Nueva Mina, 96734 Minatitlán, Ver.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1949202.4761385894!2d-94.96911479843766!3d17.410055774096175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ea280067e67f17%3A0x9356501d5dad3e25!2sDomino's%20Minatitl%C3%A1n!5e0!3m2!1ses!2smx!4v1687969552355!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/local/place/fid/0x85ea280067e67f17:0x9356501d5dad3e25/photosphere?iu=https://lh5.googleusercontent.com/p/AF1QipPB-89TO7LUSiuvXCTe54TYSfsGdfYlFCfCmrO_%3Dw160-h106-k-no-pi-0-ya16.189434-ro-0-fo100&ik=CAoSLEFGMVFpcFBCLTg5VE83TFVTaXV2WENUZTU0VFlTZnNHZGZZbEZDZkNtck9f",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img35
                },
                {
                    IdUnidad: 1,
                    UDN: "12414",
                    marca: "Domino's GPM",
                    estado: "Veracruz",
                    ciudad: "Acayucan",
                    nombreUnidad: "Plaza la Florida Express",
                    direccion: "Carretera Transístmica Oluta-Acayucan Km. 62+480, San José, 96160 Oluta, Ver.",
                    urlGoogleMaps: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7423.368440289302!2d-93.13774593124784!3d16.75452960666929!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85ecd8fc41a5c277%3A0x65a5184892c7976b!2sDomino's%20Pizza%20Boulevard!5e0!3m2!1ses!2smx!4v1687971816440!5m2!1ses!2smx",
                    urlGoogleStreetView: "https://www.google.com/maps/place/Domino's+Plaza+La+Florida/@17.9394902,-94.9099094,3a,75y,90t/data=!3m8!1e2!3m6!1sAF1QipPXhpRudiA2Mh-8OUFuCTcLqtBkX-WmvA_LLB7T!2e10!3e12!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipPXhpRudiA2Mh-8OUFuCTcLqtBkX-WmvA_LLB7T%3Dw114-h86-k-no!7i4000!8i3000!4m21!1m13!4m12!1m4!2m2!1d-93.1388531!2d16.7521326!4e1!1m6!1m2!1s0x85ea03580f5430f5:0x7cb3ef3b69ec3fe0!2sDomino's+Plaza+La+Florida!2m2!1d-94.9100051!2d17.9398843!3m6!1s0x85ea03580f5430f5:0x7cb3ef3b69ec3fe0!8m2!3d17.9398843!4d-94.9100051!10e5!16s%2Fg%2F11p0dld00d?entry=ttu",
                    IdStatus: 2,
                    status: "Abierto al publico",
                    img: img36
                }
            ]




        }
    }

    componentDidMount() {
        this.cargarDatosPantalla();
    }

    cargarDatosPantalla = () => {
        let device = Device.getDeviceSettings();
        this.setState({
            device
        });
        window.addEventListener("orientationchange", async () => {
            console.log(window.screen.orientation);
            await this.setState({
                orientation: window.screen.orientation.angle
            });
        });
    }

    abrirCerrarModalArbysUnidad = () => {
        this.setState({
            estaAbiertoModalUnidadDominos: !this.state.estaAbiertoModalUnidadDominos
        });
    }

    render() {
        return (
            <>
                <NavBar />
                <div>
                    <Block height="70px" />
                    <Row
                        style={{
                            //marginTop: "100px",
                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                        }}
                    >
                        <Col
                            style={{
                                textAlign:"center"
                            }}
                        >
                            <Label
                                style={{
                                    color: colors.azulFuerte,
                                    fontWeight: "bold",
                                    fontSize: "24px"
                                }}
                            >
                                Lista de Unidades de Domino's de
                                <Label
                                    style={{
                                        color: colors.verde,
                                        marginLeft: "7px",
                                        textShadow: "-1px -1px 0 #FFF, 1px -1px 0 #FFF, -1px 1px 0 #FFF, 1px 1px 0 #FFF",
                                    }}
                                >
                                    Grupo Penagos Meneses (GPM)
                                </Label>
                            </Label>
                        </Col>
                        <DropdownItem divider />
                    </Row>

                    <Row
                        style={{
                            marginTop: "30px",
                            marginRight: this.state.device.isDesktopDevice ? "50px" : "10px",
                            marginLeft: this.state.device.isDesktopDevice ? "50px" : "10px",
                            backgroundColor: "#E9E9E9",
                            borderRadius: "8px"
                        }}
                        xs={this.state.device.isDesktopDevice ? 4 : 1}
                    >
                        {
                            this.state.listaUnidadesDominos.map((unidad) => {
                                return (
                                    <Col>
                                        <Card
                                            style={{
                                                width: this.state.device.isDesktopDevice ? '18rem' : "100%",
                                                height: this.state.device.isDesktopDevice ? "400px" : "auto",
                                                cursor: "pointer",
                                                marginTop: "20px",
                                                marginBottom: "20px",
                                                borderRadius: "10px"
                                            }}
                                            onClick={async () => {
                                                await this.setState({
                                                    unidadDominos: unidad
                                                });
                                                await this.abrirCerrarModalArbysUnidad();
                                            }}
                                        >
                                            <img
                                                alt="Sample"
                                                src={unidad.img.length === 0 ? arbysLogo : unidad.img}
                                                height={ this.state.device.isDesktopDevice ? "200px" : "300px"}
                                                style={{
                                                    borderRadius: "10px 10px 0px 0px"
                                                }}
                                            />
                                            <CardBody>
                                                <CardTitle tag="h5">
                                                    {unidad.nombreUnidad}
                                                </CardTitle>
                                                <CardSubtitle
                                                    className="mb-2"
                                                    tag="h6"
                                                    style={{
                                                        color: unidad.IdStatus == 1 ? "grey" : unidad.IdStatus == 2 ? "green" : "#000000"
                                                    }}
                                                >
                                                    {unidad.status}
                                                </CardSubtitle>
                                                <CardSubtitle
                                                    className="mb-2"
                                                    tag="h6"
                                                    style={{
                                                    }}
                                                >
                                                    {unidad.ciudad}, {unidad.estado}
                                                </CardSubtitle>
                                                <CardText>
                                                    {unidad.direccion}
                                                </CardText>
                                            </CardBody>
                                        </Card>


                                    </Col>
                                );
                            })
                        }

                    </Row>
                </div>
                <Modal
                    isOpen={this.state.estaAbiertoModalUnidadDominos}
                    size="lg"
                    style={{
                        maxWidth: this.state.device.isDesktopDevice ? "80%" : "95%"
                    }}
                >
                    <ModalHeader
                        close={
                            <IconButton
                                style={{
                                    color: colors.blanco01
                                }}
                                onClick={() => {
                                    this.abrirCerrarModalArbysUnidad();
                                }}
                            >
                                <Close />
                            </IconButton>
                        }
                        style={{
                            backgroundColor: colors.azulFuerte,
                            color: colors.blanco01
                        }}
                    >
                        <Label
                            style={{
                                fontWeight: "bold"
                            }}
                        >
                            {this.state.unidadDominos.nombreUnidad}
                        </Label>
                    </ModalHeader>
                    <ModalBody>
                        {
                            this.state.device.isMobileDevice && (this.state.orientation == 0) ?
                                <center>
                                    <Label>
                                        Pon tu teléfono horizontal para poder ver bien el contenido.
                                    </Label>
                                </center>
                                :

                                <Row
                                    style={{
                                        marginRight: "50px",
                                        marginLeft: "50px"
                                    }}
                                >
                                    <Col
                                        style={{
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <Row>
                                            <Col
                                                style={{
                                                    maxWidth: "10%",
                                                    color: colors.rojo
                                                }}
                                            >
                                                <Room />
                                            </Col>
                                            <Col
                                                style={{
                                                    maxWidth: "90%",
                                                    color: colors.negro
                                                }}
                                            >
                                                <Row>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        Dirección
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label>
                                                        {this.state.unidadDominos.direccion}
                                                    </Label>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col
                                                style={{
                                                    maxWidth: "10%",
                                                    color: colors.rojo
                                                }}
                                            >
                                                <QueryBuilder />
                                            </Col>
                                            <Col
                                                style={{
                                                    maxWidth: "90%",
                                                    color: colors.negro
                                                }}
                                            >
                                                <Row>
                                                    <Label
                                                        style={{
                                                            fontWeight: "bold"
                                                        }}
                                                    >
                                                        Horario de Servicio
                                                    </Label>
                                                </Row>
                                                <Row>
                                                    <Label>
                                                        Lunes
                                                        <label
                                                            style={{
                                                                marginLeft: "35px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Martes
                                                        <label
                                                            style={{
                                                                marginLeft: "28px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Miércoles
                                                        <label
                                                            style={{
                                                                marginLeft: "8px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Jueves
                                                        <label
                                                            style={{
                                                                marginLeft: "30px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Viernes
                                                        <label
                                                            style={{
                                                                marginLeft: "24px"
                                                            }}
                                                        >
                                                            11:00 AM - 10:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Sábado
                                                        <label
                                                            style={{
                                                                marginLeft: "23px"
                                                            }}
                                                        >
                                                            11:00 AM - 11:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                    <Label>
                                                        Domingo
                                                        <label
                                                            style={{
                                                                marginLeft: "10px"
                                                            }}
                                                        >
                                                            11:00 AM - 11:00 PM
                                                        </label>
                                                    </Label>
                                                    <br />
                                                </Row>
                                            </Col>
                                        </Row>
                                        {
                                            this.state.unidadDominos.urlGoogleStreetView.length === 0 ?
                                                null
                                                :
                                                <Row
                                                    style={{
                                                        marginTop: "10px"
                                                    }}
                                                >
                                                    <Col
                                                        style={{
                                                            maxWidth: "10%",
                                                            color: colors.rojo
                                                        }}
                                                    >
                                                        <Streetview />
                                                    </Col>
                                                    <Col
                                                        style={{
                                                            maxWidth: "90%",
                                                            color: colors.negro
                                                        }}
                                                    >
                                                        <Row>
                                                            <Label
                                                                style={{
                                                                    fontWeight: "bold",
                                                                    cursor: "pointer"
                                                                }}
                                                                onClick={() => {
                                                                    window.open(this.state.unidadDominos.urlGoogleStreetView);
                                                                }}
                                                            >
                                                                Has click aquí para hacer recorrido con Google Street View
                                                            </Label>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                        }

                                    </Col>
                                    <Col
                                        style={{
                                            maxWidth: "100%"
                                        }}
                                    >
                                        <center>
                                            {
                                                this.state.unidadDominos.urlGoogleMaps.length === 0 ?
                                                    <>
                                                        <lottie-player
                                                            autoplay
                                                            // loop
                                                            mode="normal"
                                                            src="https://assets1.lottiefiles.com/private_files/lf30_D4yZiV.json"
                                                            speed={1}
                                                            style={{
                                                                width: "200px",
                                                                height: "200px",
                                                                // marginTop: "40px"
                                                            }}
                                                        />
                                                        <Label
                                                            style={{
                                                                fontWeight: "bold",
                                                                color: colors.negro,
                                                                fontSize: "20px"
                                                            }}
                                                        >
                                                            No se encontro un mapa de esta unidad
                                                        </Label>
                                                    </>
                                                    :
                                                    <iframe
                                                        src={this.state.unidadDominos.urlGoogleMaps}
                                                        width={500}
                                                        height={350}
                                                        style={{ border: 0 }}
                                                        allowFullScreen
                                                        loading="lazy"
                                                        referrerPolicy="no-referrer-when-downgrade"
                                                    />
                                            }

                                        </center>
                                    </Col>
                                </Row>
                        }
                    </ModalBody>
                    <ModalFooter>
                        {
                            this.state.unidadDominos.urlGoogleStreetView.length === 0 ?
                                null
                                :
                                <Button
                                    onClick={() => {
                                        window.open(this.state.unidadDominos.urlGoogleStreetView);
                                    }}
                                    style={{
                                        backgroundColor: colors.azulClaro,
                                        color: colors.blanco01,
                                        fontWeight: "bold",
                                        boxShadow: "none",
                                        borderColor: colors.azulClaro,
                                        width: "170px"
                                    }}
                                    title="Has click aqui para ver la unidad con Google Street View"
                                >
                                    Conocer Uniadad
                                </Button>
                        }

                        <Button
                            onClick={() => {
                                this.abrirCerrarModalArbysUnidad();
                            }}
                            style={{
                                backgroundColor: colors.azulClaro,
                                color: colors.blanco01,
                                fontWeight: "bold",
                                boxShadow: "none",
                                borderColor: colors.azulClaro,
                                width: "170px"
                            }}
                        >
                            Cerrar
                        </Button>
                    </ModalFooter>
                </Modal>

                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15121.368575810577!2d-91.8039118!3d18.6486365!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85f107892df91f07%3A0xc71e60166f991b7b!2sDomino's%20Cd.%20Del%20Carmen!5e0!3m2!1ses!2smx!4v1693503276036!5m2!1ses!2smx" width={600} height={450} style={{border: 0}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" /> */}

            </>
        );
    }

}

export default ListDominos;