const Colors = {
    azulClaro: "#006491",
    azulFuerte: "#005074",
    blanco: "#FFFFFF",
    blanco01: "#F6F6F6",
    negro: "#000000",
    rojo: "#D31837", 
    verde: "#129A40",
    verdeSuave: "#20BE55",
    verdeSuave_1: "#33E06D",
}

export default Colors;