import NavBar from "../components/NavBar";
import colors from "../config/Color.config";
import Device from "../config/Device.config";
import { useState, useEffect, useRef } from "react";
import { Button, Col, Label, Row } from "reactstrap";
import Block from "../components/Block";
import TimeTool from "../tools/TimeTool.tool";

/**Importación de las imágenes */
import img from "../assets/images/dominos_4.jpg";
import dominosMania from "../assets/images/dominosMania.jpg";
import viva_la_mexicana from "../assets/images/viva_la_mexicana.png"
import SConfetti from "../components/SConfetti";
import imgUnach from "../assets/images/imgUnach.png";
import dominosMania2 from "../assets/images/dominosMania2.jpg";

import img2 from "../assets/images/dominosmania_1.png";

/**Importación de las videos */
import video_2 from "../assets/videos/video_2.mp4";
import video_1 from "../assets/videos/video_1.mp4";

const PageMain = () => {

    const [device, setDevice] = useState({
        isDesktopDevice: null,
        isMobileDevice: null,
        screenHeight: 0,
        screenWidth: 0,
    });
    const [showConfetti, setShowConfetti] = useState(true);
    const [showEvent, setShowEvent] = useState(true);
    const [hoveredMouseButton, setHoveredMouseButton] = useState(false);
    // const [showText, setShowText] = useState(true);
    const videoRef = useRef(null);

    useEffect(() => {

        const screen = Device.getDeviceSettings();
        setDevice(screen);
        TimeTool.sleep(3).then(() => {
            setShowConfetti(false);
            setShowEvent(false);
        });

        // TimeTool.sleep(6).then(() => {
        //     setShowText(false);
        // });
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const callback = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    videoRef.current.play();
                } else {
                    videoRef.current.pause();
                }
            });
        };

        const observer = new IntersectionObserver(callback, options);

        if (videoRef.current) {
            observer.observe(videoRef.current);
        }

        return () => {
            if (videoRef.current) {
                observer.unobserve(videoRef.current);
            }
        };

    }, []);



    return (
        <>
            <NavBar />

            <section
                style={{
                    width: "100%",
                    height: "100vh",
                    backgroundSize: "100% 100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center center",
                    backgroundImage: device.isDesktopDevice ? `url(${img})` : "linear-gradient(to bottom, #006491, #036a98, #066f9f, #0975a6, #0c7bad, #0881b2, #0486b7, #008cbc, #0092bf, #0099c2, #009fc5, #00a5c7)",
                    fontFamily: "'Roboto Slab', serif",
                    lineHeight: 1,
                }}
            >
                {/* <Row
                    style={{
                        marginRight: "0px",
                        marginLeft: "0px"
                    }}
                >
                    <SConfetti />
                </Row> */}
                <Block height={device.isDesktopDevice ? "20vh" : "70px"} />
                <Row
                    style={{
                        marginRight: "0px",
                        marginLeft: "0px",

                    }}
                >
                    <center>
                        <Label
                            style={{
                                borderRadius: '10px',
                                padding: "5px",
                                fontWeight: "bold",
                                fontSize: device.isDesktopDevice ? "48px" : "36px",
                                color: colors.blanco01,
                                textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                            }}
                        >
                            El mejor invento del hombre después <br /> de la rueda es la Domino's
                        </Label>
                    </center>
                </Row>
                <Row
                    style={{
                        marginRight: "0px",
                        marginLeft: "0px",
                        marginTop: "20px"
                    }}
                >
                    <center>
                        <Label
                            style={{
                                borderRadius: '10px',
                                padding: "5px",
                                fontWeight: "bold",
                                fontSize: device.isDesktopDevice ? "48px" : "36px",
                                color: device.isDesktopDevice ? colors.azulClaro : colors.blanco,
                                textShadow: `-0.7px -0.7px 0 ${device.isDesktopDevice ? "#FFF" : "#005074"}, 0.7px -0.7px 0 ${device.isDesktopDevice ? "#FFF" : "#005074"}, -0.7px 0.7px 0 ${device.isDesktopDevice ? "#FFF" : "#005074"}, 0.7px 0.7px 0 ${device.isDesktopDevice ? "#FFF" : "#005074"}`,
                            }}
                        >
                            Domino's GMP
                        </Label>
                    </center>
                </Row>

                <Row
                    style={{
                        marginRight: "0px",
                        marginLeft: "0px",
                        marginTop: "5px"
                    }}
                >
                    {/* <center>
                        {
                            showText ?
                                <>
                                    <label
                                        style={{
                                            fontSize: device.isDesktopDevice ? "72px" : "48px",
                                            color: colors.blanco,
                                            fontFamily: "'Roboto Slab', serif",
                                            color: "#086D4D",
                                            textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                                            marginLeft: "15px",
                                            transition: "1s",
                                        }}
                                    >
                                        !Viva
                                    </label>
                                    <label
                                        style={{
                                            fontSize: device.isDesktopDevice ? "72px" : "48px",
                                            fontFamily: "'Roboto Slab', serif",
                                            color: "#FFF",
                                            textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                                            marginLeft: "15px",
                                            transition: "1s",
                                        }}
                                    >
                                        Mexico
                                    </label>
                                    <label
                                        style={{
                                            fontSize: device.isDesktopDevice ? "72px" : "48px",
                                            fontFamily: "'Roboto Slab', serif",
                                            color: "#D0182D",
                                            textShadow: "-1px -1px 0 black, 1px -1px 0 black, -1px 1px 0 black, 1px 1px 0 black",
                                            marginLeft: "15px",
                                            transition: "1s",
                                        }}
                                    >
                                        Señores¡
                                    </label>
                                </>
                                :
                                <Label
                                    style={{
                                        fontSize: device.isDesktopDevice ? "48px" : "36px",
                                        fontWeight: "bold",
                                        color: device.isDesktopDevice ? colors.azulFuerte : colors.blanco,
                                        fontFamily: "'Roboto Slab', serif",
                                        textShadow: `-0.7px -0.7px 0 ${device.isDesktopDevice ? "#FFF" : "#005074"}, 0.7px -0.7px 0 ${device.isDesktopDevice ? "#FFF" : "#005074"}, -0.7px 0.7px 0 ${device.isDesktopDevice ? "#FFF" : "#005074"}, 0.7px 0.7px 0 ${device.isDesktopDevice ? "#FFF" : "#005074"}`,
                                    }}
                                >
                                    Grupo Penagos Meneses
                                </Label>
                        }
                    </center> */}
                </Row>
                <Row
                    style={{
                        marginRight: "0px",
                        marginLeft: "0px",
                        marginTop: "30px"
                    }}
                >
                    <center>
                        <Button
                            style={{
                                backgroundColor: colors.rojo,
                                color: colors.blanco01,
                                fontWeight: "bold",
                                fontSize: "24px",
                                borderRadius: "30px",
                                borderColor: colors.blanco01,
                                boxShadow: "none",
                                transform: hoveredMouseButton ? 'scale(1.1)' : 'scale(1)',
                                transition: 'transform 0.3s ease-in-out',
                            }}
                            onClick={() => {
                                window.location.href = "/unidades";
                            }}
                            onMouseEnter={() => {
                                setHoveredMouseButton(true);
                            }}
                            onMouseLeave={() => {
                                setHoveredMouseButton(false);
                            }}
                        >
                            Conoce nuestras unidades
                        </Button>
                    </center>
                </Row>
            </section>

            {
                device.isDesktopDevice ?
                    <>
                        <section
                            style={{
                                width: "100%",
                                height: "auto",
                                fontFamily: "'Roboto Slab', serif",
                            }}
                        >
                            <Row
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "100vh",
                                    overflow: "hidden",
                                    marginRight: "0px",
                                    marginLeft: "0px"
                                }}
                            >
                                <Col
                                    style={{
                                        maxWidth: device.isDesktopDevice ? "50%" : "100%",
                                        width: device.isDesktopDevice ? "50%" : "100%",
                                    }}
                                >

                                    <video
                                        ref={videoRef}
                                        autoPlay
                                        muted
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            //right: 0,
                                            marginRight: "0px",
                                            marginLeft: "0px",
                                            width: "50%",
                                            height: "auto",
                                            objectFit: "cover",
                                        }}
                                    >
                                        <source src={video_2} type="video/mp4" />
                                        Tu navegador no admite la reproducción de video.
                                    </video>
                                </Col>
                                <Col
                                    style={{
                                        maxWidth: device.isDesktopDevice ? "50%" : "100%",
                                        width: device.isDesktopDevice ? "50%" : "100%",
                                        textAlign: "center",
                                        backgroundImage: `url(${imgUnach})`,
                                        backgroundSize: "cover",
                                        textShadow: "-0.7px -0.7px 0 #FFF, 0.7px -0.7px 0 #FFF, -0.7px 0.7px 0 #FFF, 0.7px 0.7px 0 #FFF",
                                    }}
                                >
                                    <Row
                                        style={{
                                            marginTop: "150px",

                                        }}
                                    >
                                        <Label
                                            style={{
                                                color: colors.azulFuerte,
                                                fontWeight: "bold",
                                                fontSize: "46px"
                                            }}
                                        >
                                            Nuestros Horarios
                                        </Label>
                                    </Row>
                                    <Row
                                        style={{
                                            color: colors.azulFuerte,
                                            fontWeight: "bold",
                                            fontSize: "22px"
                                        }}
                                    >
                                        <Label>
                                            Lunes 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                        <Label>
                                            Martes 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                        <Label>
                                            Miércoles 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                        <Label>
                                            Jueves 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                        <Label>
                                            Viernes 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                        <Label>
                                            Sábado 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                        <Label>
                                            Domingo 09:00 am. - 11:00 pm
                                        </Label>
                                        <br />
                                    </Row>
                                    <Row>
                                        <Label
                                            style={{
                                                color: colors.azulFuerte,
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                                lineHeight: 1,
                                                marginTop: "10px"
                                            }}
                                        >
                                            Los horarios de plazas son diferentes, <br /> conoce nuestras unidades y encuentra la mas cercana.
                                        </Label>
                                    </Row>
                                    <Row>
                                        <center>
                                            <Button
                                                style={{
                                                    backgroundColor: colors.rojo,
                                                    color: colors.blanco01,
                                                    fontWeight: "bold",
                                                    fontSize: "24px",
                                                    borderRadius: "30px",
                                                    borderColor: colors.blanco01,
                                                    boxShadow: "none",
                                                    transform: hoveredMouseButton ? 'scale(1.1)' : 'scale(1)',
                                                    transition: 'transform 0.3s ease-in-out',
                                                    width: "auto",
                                                    marginTop: "10px"
                                                }}
                                                onClick={() => {
                                                    window.location.href = "/unidades";
                                                }}
                                                onMouseEnter={() => {
                                                    setHoveredMouseButton(true);
                                                }}
                                                onMouseLeave={() => {
                                                    setHoveredMouseButton(false);
                                                }}
                                            >
                                                Conoce nuestras unidades
                                            </Button>
                                        </center>
                                    </Row>
                                </Col>

                            </Row>

                        </section>
                        <section
                            style={{
                                width: "100%",
                                height: "100vh"
                            }}
                        >
                            <img
                                src={dominosMania}
                                alt="No se pudo cargar la imagen"
                                style={{
                                    width: "100%",
                                    height: "100vh",
                                }}
                            />
                        </section>

                        <section
                            style={{
                                width: "100%",
                                height: "auto",
                            }}
                        >

                            <Row
                                style={{
                                    position: "relative",
                                    width: "100%",
                                    height: "100vh",
                                    overflow: "hidden",
                                    marginRight: "0px",
                                    marginLeft: "0px"
                                }}
                            >
                                <Col
                                    style={{
                                        textAlign: "center",
                                        backgroundImage: `url(${viva_la_mexicana})`,
                                        backgroundSize: "cover",
                                        textShadow: "-0.7px -0.7px 0 #FFF, 0.7px -0.7px 0 #FFF, -0.7px 0.7px 0 #FFF, 0.7px 0.7px 0 #FFF",
                                    }}
                                >
                                </Col>
                                <Col
                                >

                                    <video
                                        ref={videoRef}
                                        autoPlay
                                        muted
                                        style={{
                                            position: "absolute",
                                            right: 0,
                                            marginRight: "0px",
                                            marginLeft: "0px",
                                            width: "50%",
                                            height: "100vh",
                                            objectFit: "cover",
                                        }}
                                        onMouseEnter={()=>{                                
                                            videoRef.current.play();
                                        }}
                                    >
                                        <source src={video_1} type="video/mp4" />
                                        Tu navegador no admite la reproducción de video.
                                    </video>
                                </Col>


                            </Row>
                        </section>
                    </>
                    :
                    <>
                        <section
                            style={{
                                width: "100%",
                                height: "auto",
                            }}
                        >
                            <video
                                ref={videoRef}
                                autoPlay
                                muted
                                style={{
                                    //position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    // marginRight: "0px",
                                    // marginLeft: "0px",
                                    width: "100%",
                                    //height: "100%",
                                    objectFit: "cover",
                                }}
                            >
                                <source src={video_2} type="video/mp4" />
                                Tu navegador no admite la reproducción de video.
                            </video>
                        </section>
                        <section
                            style={{
                                width: "100%",
                                height: "100vh",
                                textAlign: "center",
                                backgroundImage: `url(${imgUnach})`,
                                backgroundSize: "cover",
                                textShadow: "-0.7px -0.7px 0 #FFF, 0.7px -0.7px 0 #FFF, -0.7px 0.7px 0 #FFF, 0.7px 0.7px 0 #FFF",
                            }}
                        >
                            <Row
                                style={{
                                    marginRight: "0px",
                                    marginLeft: "0px"
                                }}
                            >
                                <Label
                                    style={{
                                        color: colors.azulFuerte,
                                        fontWeight: "bold",
                                        fontSize: "46px"
                                    }}
                                >
                                    Nuestros Horarios
                                </Label>
                            </Row>
                            <Row
                                style={{
                                    color: colors.azulFuerte,
                                    fontWeight: "bold",
                                    fontSize: "22px",
                                    marginRight: "0px",
                                    marginLeft: "0px"
                                }}
                            >
                                <Label>
                                    Lunes 09:00 am. - 11:00 pm
                                </Label>
                                <br />
                                <Label>
                                    Martes 09:00 am. - 11:00 pm
                                </Label>
                                <br />
                                <Label>
                                    Miércoles 09:00 am. - 11:00 pm
                                </Label>
                                <br />
                                <Label>
                                    Jueves 09:00 am. - 11:00 pm
                                </Label>
                                <br />
                                <Label>
                                    Viernes 09:00 am. - 11:00 pm
                                </Label>
                                <br />
                                <Label>
                                    Sábado 09:00 am. - 11:00 pm
                                </Label>
                                <br />
                                <Label>
                                    Domingo 09:00 am. - 11:00 pm
                                </Label>
                                <br />
                            </Row>
                            <Row
                                style={{
                                    marginRight: "0px",
                                    marginLeft: "0px"
                                }}
                            >
                                <Label
                                    style={{
                                        color: colors.azulFuerte,
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                        lineHeight: 1,
                                        marginTop: "10px"
                                    }}
                                >
                                    Los horarios de plazas son diferentes, <br /> conoce nuestras unidades y encuentra la mas cercana.
                                </Label>
                            </Row>
                            <Row
                                style={{
                                    marginRight: "0px",
                                    marginLeft: "0px"
                                }}
                            >
                                <center>
                                    <Button
                                        style={{
                                            backgroundColor: colors.rojo,
                                            color: colors.blanco01,
                                            fontWeight: "bold",
                                            fontSize: "24px",
                                            borderRadius: "30px",
                                            borderColor: colors.blanco01,
                                            boxShadow: "none",
                                            transform: hoveredMouseButton ? 'scale(1.1)' : 'scale(1)',
                                            transition: 'transform 0.3s ease-in-out',
                                            width: "auto",
                                            marginTop: "10px"
                                        }}
                                        onClick={() => {
                                            window.location.href = "/unidades";
                                        }}
                                        onMouseEnter={() => {
                                            setHoveredMouseButton(true);
                                        }}
                                        onMouseLeave={() => {
                                            setHoveredMouseButton(false);
                                        }}
                                    >
                                        Conoce nuestras unidades
                                    </Button>
                                </center>
                            </Row>
                        </section>
                        <section
                            style={{
                                width: "100%",
                                height: "100vh",
                                // textAlign: "center",
                                // backgroundImage: `url(${dominosMania2})`,
                                // backgroundSize: "cover",
                                textShadow: "-0.7px -0.7px 0 #FFF, 0.7px -0.7px 0 #FFF, -0.7px 0.7px 0 #FFF, 0.7px 0.7px 0 #FFF",
                            }}
                        >
                            <img
                                src={dominosMania2}
                                alt=""
                                style={{
                                    width: "100%",
                                    height: "100vh"
                                }}
                            />
                        </section>
                        <section
                            style={{
                                width: "100%",
                                height: "auto",
                            }}
                        >
                            <video
                                ref={videoRef}
                                autoPlay
                                muted
                                style={{
                                    //position: "absolute",
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    // marginRight: "0px",
                                    // marginLeft: "0px",
                                    width: "100%",
                                    //height: "100%",
                                    objectFit: "cover",
                                }}
                            >
                                <source src={video_1} type="video/mp4" />
                                Tu navegador no admite la reproducción de video.
                            </video>
                        </section>
                        <section
                            style={{
                                width: "100%",
                                height: "100vh",
                                // textAlign: "center",
                                // backgroundImage: `url(${dominosMania2})`,
                                // backgroundSize: "cover",
                                textShadow: "-0.7px -0.7px 0 #FFF, 0.7px -0.7px 0 #FFF, -0.7px 0.7px 0 #FFF, 0.7px 0.7px 0 #FFF",
                            }}
                        >
                            <img
                                src={img2}
                                alt=""
                                style={{
                                    width: "100%",
                                    height: "100vh"
                                }}
                            />
                        </section>
                    </>
            }







        </>
    );
}

export default PageMain;