import React, { useState } from 'react';
import {
    Navbar,
    Nav,
    Button,
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Row,
    Col,
} from 'reactstrap';

import colors from "../config/Color.config";
import {
    Menu,
} from '@material-ui/icons';



const NavBarMovil = (props) => {
    const [isOpen, setIsOpen] = useState(false);


    const [isButtonHover, setButtonHover] = useState({
        0: false,
        1: false,
        2: false,
        3: false,
        4: false,
    });

    const open = () => setIsOpen(!isOpen);


    return (

        <div

        >
            <Navbar
                expand="md"
                style={{
                    backgroundColor: colors.azulClaro,
                    height: "62px",
                    width: "100%",
                    position: "fixed",
                    zIndex: 100,
                    fontFamily: "'Roboto Slab', serif",
                }}
            >
                <Nav className="ml-auto" expand="md" navbar style={{ marginTop: "-5px" }}>
                </Nav>
                <Button
                    //color="primary"
                    style={{ backgroundColor: "#fff", borderColor: "#fff", boxShadow: "none", color: colors.azulClaro, fontWeight: "bold" }}
                    onClick={open}
                >
                    <Menu style={{ color: "#016390" }} />
                </Button>
                <Offcanvas
                    toggle={open}
                    isOpen={isOpen}
                    direction="end"
                    style={{
                        backgroundColor: "#fff"
                    }}
                >
                    <OffcanvasHeader toggle={open}>
                        <label
                            style={{
                                color: colors.azulClaro,
                                fontWeight: "bold",
                                fontSize: "36px",
                                fontFamily: "'Roboto Slab', serif",
                            }}
                        >
                            Menu
                        </label>
                    </OffcanvasHeader>
                    <OffcanvasBody>
                        <Nav vertical style={{fontFamily: "'Roboto Slab', serif",}} >

                            <Row  >
                                <Col>
                                    <Button
                                        style={{
                                            marginLeft: "5px",
                                            backgroundColor: colors.blanco,
                                            color: colors.azulClaro,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderRadius: "0px 0px 0px 0px",
                                            borderColor: "transparent",
                                            fontSize: "24px"
                                        }}
                                        onClick={() => {
                                            window.location.href = "/";
                                        }}
                                    >

                                        Inicio
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            marginLeft: "5px",
                                            backgroundColor: colors.blanco,
                                            color: colors.azulClaro,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderRadius: "0px 0px 0px 0px",
                                            borderColor: "transparent",
                                            fontSize: "24px"
                                        }}
                                        onClick={() => {
                                            window.location.href = "/unidades";
                                        }}
                                    >
                                        Nuestras Unidades
                                    </Button>

                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            marginLeft: "5px",
                                            backgroundColor: colors.blanco,
                                            color: colors.azulClaro,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderRadius: "0px 0px 0px 0px",
                                            borderColor: "transparent",
                                            fontSize: "24px"
                                        }}
                                        onClick={() => {
                                            window.location.href = "https://dominoswow.mx/tyc/";
                                        }}
                                    >
                                       Términos y Condiciones
                                    </Button>

                                </Col>
                            </Row>
                            <Row>
                                <Col
                                    style={{
                                        textAlign: "left"
                                    }}
                                >
                                    <Button
                                        style={{
                                            marginLeft: "5px",
                                            backgroundColor: colors.blanco,
                                            color: colors.azulClaro,
                                            boxShadow: "none",
                                            fontWeight: "bold",
                                            borderRadius: "0px 0px 0px 0px",
                                            borderColor: "transparent",
                                            fontSize: "24px"
                                        }}
                                        onClick={() => {
                                            window.location.href = "/";
                                        }}
                                    >
                                        GPM
                                    </Button>
                                </Col>
                            </Row>
                        </Nav>

                    </OffcanvasBody>

                </Offcanvas>
            </Navbar>
        </div >


    );
}

export default NavBarMovil;