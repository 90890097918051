
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";

import PageMain from "./pages/PageMain";
import ListDominos from "./pages/ListDominos";

const  App = () => {

  console.clear();

  return (

    <Router>
      <Routes>
        <Route exact path="/" element={<PageMain/>}/>    
        <Route exact path="/unidades" element={<ListDominos/>}/>    
      </Routes>
    </Router>
    
  );
}

export default App;
