


const getDeviceSettings = () => {

    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    let isDesktopDevice = !regexp.test(details); 
    let isMobileDevice = !isDesktopDevice;  
    let screenHeight = window.innerHeight;
    let screenWidth = window.innerWidth;
  
    let device = {
      isDesktopDevice,
      isMobileDevice,
      screenHeight,
      screenWidth
    }

    return device;

}

const ScreenTools = {
    getDeviceSettings,
}

export default ScreenTools;