import { useEffect, useState } from "react";
import Device from "../config/Device.config"
import NavBarDesktop from "./NavBarDesktop";
import NavBarMovil from "./NavBarMovil";

const NavBar = () => {

    const [device, setDevice] = useState({
        isDesktopDevice: null,
        isMobileDevice: null,
        screenHeight: 0,
        screenWidth: 0,
    });


    useEffect(() => {
        const screen = Device.getDeviceSettings();
        setDevice(screen);
    }, []);

    return (
        device.isDesktopDevice ?
            <NavBarDesktop />
            :
            <NavBarMovil />
    )

}

export default NavBar;